<template>
  <div class="spec_warp">
    <div class="specification">
      <div class="title">服务规格</div>
      <ch-form :render-option="specifyOption" :model="specifyModalForm"
               :splice="[10]" ref="specifyForm" :rules="specifyRules"
               :props="{ labelWidth: '150px' , paddingRight: '80px'}" >
        <template #ServiceDet>
          <div>
            <div style="display: flex;">
              <div style="display: flex;width:400px;flex-wrap: wrap">
                <el-input v-for="(item,index) in specifyModalForm.serviceDet" v-model="item.text" :key="index" maxlength="20"
                          type="text" show-word-limit size="small" placeholder='自定义输入服务明细，例如"全屋清扫"' />
              </div>
              <div>
                <ch-button style="height: 30px" @click="delService" type="cancel">删除</ch-button>
                <ch-button style="height: 30px" @click="addService" type="ok">添加</ch-button>
              </div>
            </div>
            <div style="font-size: 14px;color: #0000003F">可自定义输入服务明细，如"全屋清扫"，添加了服务明细之后服务人员只能完成明细之后才可正常签离</div>
          </div>
        </template>
      </ch-form>
    </div>

    <div class="tableData">
      <div class="title">服务价格<span style="color: red">*</span></div>
      <el-table
          :data="tableData"
          style="width: 70%;margin: 20px 0"
          size="mini" :border="true" :header-cell-style="{background:'#CCCCCC'}"
      >
        <el-table-column prop="specName" label="规格名称"></el-table-column>
        <el-table-column prop="stipulate" label="房屋面积/㎡">
          <div  class="item" slot-scope="scope">
            <el-input size="mini" class="item__input" v-model="scope.row.stipulate" placeholder="面积"></el-input>
          </div>
        </el-table-column>
        <el-table-column prop="duration" label="服务时长/小时"></el-table-column>
        <el-table-column prop="once" label="1次(元)原价/售价">
          <div  class="item" slot-scope="scope">
            <el-input size="mini" class="item__input" style="margin-right: 5px;" v-model="scope.row.onceOriginalCost"
                      @input="(value)=> scope.row.onceOriginalCost = value.replace(/[^0-9.]/g,'')" @change="() => {
                        scope.row.onceOriginalCost = scope.row.onceOriginalCost? parseFloat(scope.row.onceOriginalCost, 10).toFixed(2):0.00;
                      }"
                      placeholder="原价"></el-input>
            <el-input size="mini" class="item__input" v-model="scope.row.once" @input="(value)=> scope.row.once = value.replace(/[^0-9.]/g,'')" @change="() => {
                        scope.row.once = scope.row.once? parseFloat(scope.row.once, 10).toFixed(2):0.00;
                        if(scope.$index===0){
                          specifyModalForm.minPrice = scope.row.once;
                        }
                      }" placeholder="售价"></el-input>
          </div>
        </el-table-column>
        <el-table-column prop="twice" label="2次(元)原价/售价">
          <div  class="item" slot-scope="scope">
            <el-input size="mini" class="item__input" style="margin-right: 5px;" v-model="scope.row.twiceOriginalCost"
                      @input="(value)=> scope.row.twiceOriginalCost = value.replace(/[^0-9.]/g,'')" @change="() => {
                        scope.row.twiceOriginalCost = scope.row.twiceOriginalCost? parseFloat(scope.row.twiceOriginalCost, 10).toFixed(2):0.00;
                      }" placeholder="原价"></el-input>
            <el-input size="mini" class="item__input" v-model="scope.row.twice"
                      @input="(value)=> scope.row.twice = value.replace(/[^0-9.]/g,'')" @change="() => {
                        scope.row.twice = scope.row.twice? parseFloat(scope.row.twice, 10).toFixed(2):0.00;
                      }"  placeholder="售价"></el-input>
          </div>
        </el-table-column>
        <el-table-column prop="three" label="3次(元)原价/售价">
          <div  class="item" slot-scope="scope">
            <el-input size="mini" class="item__input" style="margin-right: 5px;" v-model="scope.row.threeOriginalCost"
                      @input="(value)=> scope.row.threeOriginalCost = value.replace(/[^0-9.]/g,'')"
                      @change="() => {
                        scope.row.threeOriginalCost = scope.row.threeOriginalCost? parseFloat(scope.row.threeOriginalCost, 10).toFixed(2):0.00;
                      }" placeholder="原价"></el-input>
            <el-input size="mini" class="item__input" v-model="scope.row.three"
                      @input="(value)=> scope.row.three = value.replace(/[^0-9.]/g,'')"
                      @change="() => {
                        scope.row.three = scope.row.three? parseFloat(scope.row.three, 10).toFixed(2):0.00;
                      }" placeholder="售价"></el-input>
          </div>
        </el-table-column>
      </el-table>
      <div style="margin-bottom: 10px;">
        显示价格：
        <el-select v-model="specifyModalForm.minPrice" size="mini" placeholder="请选择" style="margin-right: 5px">
           <el-option
              v-for="(item,index) in priceOption"
              :key="index"
              :label="item.label"
              :value="item.value">
           </el-option>
        </el-select>元
      </div>
      <div style="margin-bottom: 22px;display: flex;align-items: center;">
        夜间服务：<el-checkbox @change="nightSonChange" v-model="nightSon.nightService" :true-label="1" :false-label="0" />
        <el-radio-group style="margin-left: 20px;" v-model="nightSon.raisePriceType" @change="raisePriceInput">
          <el-radio :label="1">单次固定加价<el-input v-model="nightSon.raisePriceFixation" @focus="inputFocus(1)" @input="inputValueReplace($event,'raisePriceFixation')" @blur="handleBlur('raisePriceFixation')" size="mini" style="margin:0 5px;width: 70px"></el-input>元</el-radio>
          <el-radio :label="2">单次按比例加价<el-input v-model="nightSon.raisePricePercentage" @focus="inputFocus(2)" @input="inputValueReplace($event,'raisePricePercentage')" @blur="handleBlur('raisePricePercentage')" size="mini" style="margin:0 5px;width: 70px"></el-input>%</el-radio>
        </el-radio-group>
      </div>
      <div class="footer" style="color: #0000003f;font-size: 14px">
        <ul>
          <li>1.单价、折扣为必填项</li>
          <li>2.单价为最低服务时长的价格</li>
          <li>3.请谨慎填写服务时长的价格。服务N时长价格=最低服务时长的单价*折扣(折扣价设置为准)，不设置折扣价可自定于填写价格</li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>
import {Message} from "element-ui";
import _ from "lodash";

export default {
  props:{
    modalData:{
      type:Object,
      default:function (){
        return {}
      }
    },
    table:{
      type:Array,
    },
    night: {
      type: Object
    }
  },
  created() {
    this.$nextTick(()=>{
      this.$refs.specifyForm?.clearValidate();
      if(this.modalData.specName||this.modalData.serviceTime) {
        this.specifyModalForm = this.modalData
      }
      this.tableData = this.table
      if(this.night.nightService !==undefined) {
        this.nightSon = this.night
      }
      if(this.nightSon.nightService===0) {
        this.nightSon.raisePriceType = 0
        this.nightSon.raisePrice = ''
      }
      if(this.nightSon.raisePriceType===1){
        this.nightSon.raisePriceFixation = this.nightSon.raisePrice
        this.nightSon.raisePricePercentage = ''
      }else if(this.nightSon.raisePriceType===2){
        this.nightSon.raisePriceFixation = ''
        this.nightSon.raisePricePercentage = this.nightSon.raisePrice
      }

    })
  },
  data(){
    return {
      specifyModalForm:{
        specName:'',
        serviceTime:'',
        overtimePay:'',
        specDetail:'',
        serviceDet:[
          {text:''},
        ],
        minPrice:''
      },
      tableData:[],
      specifyRules:{
        specName: [{ required: true, message: "请输入规格名称", trigger: ["blur", "change"] }],
        serviceTime: [{ required: true, message: "请选择服务时长", trigger: ["blur",] }],
        overtimePay: [{ required: true, message: "请输入单次超时价格", trigger: ["blur", "change"] }],
      },
      nightSon:{
        nightService: 0, // 夜间服务
        raisePriceType: 0 ,// 加价类型(0:不加,1:固定,2:百分比)
        raisePrice: null, // 加价
        raisePriceFixation: null,
        raisePricePercentage: null,
      },
    }
  },
  watch: {
    'specifyModalForm.serviceTime':_.debounce(function (val) {
      this.specifyModalForm.serviceTime = val?.sort()
      if (val && this.specifyModalForm.serviceTime?.length > 0 ) {
        this.specifyModalForm.serviceTime.forEach((time) => {
          // 响应式data的数据
          let data = {
            specName: this.specifyModalForm.specName,
            stipulate: '<50',
            duration: time,
            once:  '',
            onceOriginalCost: '',
            twice: '',
            twiceOriginalCost: '',
            three: '',
            threeOriginalCost: '',
          }
          this.tableData.push(data)
          // 根据时间去重
          this.tableData = _.uniqBy(this.tableData, 'duration')?.sort((a, b) => {
            return a.duration - b.duration
          }).filter(item => this.specifyModalForm.serviceTime.includes(item.duration))
        })
      }
    }),
    'specifyModalForm.specName':_.debounce(function (val){
      if(val){
        this.tableData = this.tableData.map((item)=>{
          return {
            ...item,
            specName:val
          }
        })
      }
    },300),
  },
  computed:{
    specifyOption:function (){
      return [
        {type: 'input', label: '规格名称：', prop: 'specName', placeholder: '单次套餐填服务名称，多次套餐命名为：服务名称+服务次数，例：日常保洁3次',
          props:{showWordLimit:true,maxlength:20} },
        {type: 'select', label: '服务时长：', prop: 'serviceTime', placeholder: '例:2，3，4，5,最多可选4个时间',
          name: "name", value: "name",props: { multiple: true,multipleLimit:4}, option: [
            {name:1}, {name:2}, {name:3}, {name:4}, {name:5},
            {name:6}, {name:7}, {name:8}, {name:9}, {name:10},
          ]
        },
        {type: 'number', label: '单次超时价格：', prop: 'overtimePay', placeholder: '请输入以小时为单位，单次超时服务工作价格',sign:'元'},
        {type: 'input', label: '规格描述：', prop: 'specDetail', placeholder: '请输入' },
        {type: 'slot',  label: '服务明细：', slotName: 'ServiceDet',prop: 'serveDetail'},
      ]
    },
    priceOption:function(){
      return this.tableData.map((item)=>{
        return [item.once,item.twice,item.three]
      }).flat().filter(item => item).sort((a,b) => (a - b)).reduce((acc, item) => {
        if (!acc.some((obj) => obj.value === item)) {
          acc.push({ value: item, label: item });
        }
        return acc;
      }, []);
    }
  },
  methods:{
    addService(){
      if(this.specifyModalForm.serviceDet.length>=10){
        Message({type: "error", message: "最多只能添加10个服务明细!",})
      }
      this.specifyModalForm.serviceDet.push({text:""})
    },
    delService(){
      if(this.specifyModalForm.serviceDet.length<=1){
        Message({type: "error", message: "不可以继续删除了!",})
        return
      }
      this.specifyModalForm.serviceDet.pop()
    },
    getData(){
      return this.specifyModalForm
    },
    getTableDate(){
      const tableFilter = []
      this.tableData.forEach(item=>{
        if((item.once && !item.onceOriginalCost) || (item.onceOriginalCost && !item.once)){
          tableFilter.push(item)
        }
        if((item.twice && !item.twiceOriginalCost) || (item.twiceOriginalCost && !item.twice)){
          tableFilter.push(item)
        }
        if((item.three && !item.threeOriginalCost) || (item.threeOriginalCost && !item.three)){
          tableFilter.push(item)
        }
      })
      if(tableFilter.length>0){
        this.$message({type: "error", message: "原价和售价必须一起填写完整"})
        return
      }
      return this.tableData
    },
    getNightServe() {
      this.nightSon.raisePrice = this.nightSon.raisePriceFixation || this.nightSon.raisePricePercentage
      return this.nightSon
    },
    nightSonChange(event){
      if(event===0){
        this.nightSon.raisePriceType = 0
        this.nightSon.raisePrice = ''
        this.nightSon.raisePriceFixation = ''
        this.nightSon.raisePricePercentage = ''
      }
    },
    raisePriceInput() {
      this.$forceUpdate()
      this.nightSon.raisePrice = ''
      this.nightSon.raisePriceFixation = ''
      this.nightSon.raisePricePercentage = ''
    },
    handleBlur(prop) {
      this.nightSon[prop] = this.nightSon[prop] ? parseFloat(this.nightSon[prop], 10).toFixed(2) : '';
    },
    inputValueReplace(value,prop) {
      this.nightSon[prop] = value.replace(/[^0-9.]/g,'')
    },
    inputFocus(type) {
      this.nightSon.nightService = 1
      if (type === 1){
        this.nightSon.raisePriceType = 1
      }else {
        this.nightSon.raisePriceType = 2
      }
      if(type !== this.nightSon.raisePriceType){
        this.nightSon.raisePrice = ''
        this.nightSon.raisePriceFixation = ''
        this.nightSon.raisePricePercentage = ''
      }
    },
  },
  destroyed() {
    this.nightSon = {}
  }
}
</script>
<style scoped lang="scss">
.spec_warp{
  .specification,
  .tableData{
    padding: 20px 40px;
    .tagsList{
      .select_warp{
        display: flex;
        .button{
          height: 30px;
          line-height: 0;
        }
      }
      .tip{
        color: #999999;
        font-size: 12px;
      }
    }
  }
  .tableData{
    padding: 20px 40px;
    .item__input{
      width: 60px;
      /* 调整elementUI中样式 如果不需要调整请忽略 */
      ::v-deep .el-input__inner{
        padding: 0 5px !important;
        text-align: center;
      }
      .el-input__suffix{
        i{
          font-size: 12px !important;
          line-height: 26px !important;
        }
      }
    }
    .item__txt{
      box-sizing: border-box;
      line-height: 30px;
      padding: 0 9px;
    }
  }
  .specification {
    .title {
      margin-bottom: 10px;
    }
  }
}
.title{
  font-weight: bold;
}
</style>